/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.scss in this case)
import '../css/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

let toggleMenu = $( 'div.toggleMenu' );
let toggleMenuIcon = $( 'div.toggleMenu i' );
let profileContent = $( 'div.profile-content' );
let profilePhoto = $( 'div.profile-photo' );
let mainContent = $( 'div.main-content' );
//
// Notification.requestPermission(result => {
//     if (result === 'granted') {
//         showNotification('So nice to have you here!', 'Hey there!')
//     }
// });
//
// function showNotification(title, message) {
//     if ('Notification' in window) {
//         navigator.serviceWorker.ready.then(registration => {
//             registration.showNotification(title, {
//                 body: message,
//                 tag: 'vibration-sample'
//             });
//         });
//     }
// }

$(document).ready(function() {

    toggleMenu.on( "click", function() {
        // Toggle class for profileContent and MainContent
        profileContent.toggleClass( "col-lg-2 col-lg-1" );
        profilePhoto.toggleClass( "hidden" );
        mainContent.toggleClass( "col-lg-7 col-lg-8" );
        //Change icon on other direction
        toggleMenuIcon.toggleClass( "icon-arrow-left icon-arrow-right" );
    });

    var colors = [
        'QTee_White',
        'QTee_Green',
        'QTee_Red',
        'QTee_Blue',
        'QTee_White',
    ];
    $('#carouselQTeeControls').on('slid.bs.carousel', function() {
        var currentIndex = $('div.qtee.active').index();
        console.log(colors[currentIndex]);
        $('img#qtee_img').attr("src","/build/images/products/"+colors[currentIndex]+".png");
    });

});

var myCanvas = document.getElementById('threejs'), geometry;


//renderer
var renderer = new THREE.WebGLRenderer({ canvas: myCanvas, antialias: true });
renderer.setClearColor(0x808080);
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(500, 300);

var camera = new THREE.PerspectiveCamera(60, 500/300, 100, 1500);
camera.position.set(0, 0, 1000);

//SCENE
var scene = new THREE.Scene();

var controls = new THREE.OrbitControls(camera, renderer.domElement);

var light = new THREE.AmbientLight(0xffffff, 0.7);
scene.add(light);

var light2 = new THREE.PointLight(0xffffff, 0.7);
scene.add(light2);

var light3 = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
scene.add( light3 );

var obj;
var loader = new THREE.GLTFLoader();
loader.load(
    // resource URL
    '/build/models/qtee.gltf',
    // called when the resource is loaded
    function ( gltf ) {

        gltf.scene.traverse( child => {
            if ( child.material ) child.material.metalness = 0;
        } );

        scene.add( gltf.scene );

        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object

    },
    // called while loading is progressing
    function ( xhr ) {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    },
    // called when loading has errors
    function ( error ) {
        console.log( 'An error happened' );
    }
);

render();
function render() {
    renderer.render(scene, camera);
    requestAnimationFrame(render);
}